$dark-blue: #121e2d;
$light-blue: rgb(5, 105, 177);
$font-color: #d9d9d9;
$white: #ffffff;
$yellow: #f9c728;
$dark-yellow: #f8c629;
$dark-grey: #434f64;
$semi-dark-grey: #686f7c;
$bg-dark-grey: #3b4049;
$semi-dark-blue: #1c293b;
$semi-font-color: #00000029;
// GREEN
$green: #69bb16;
$lite-green: #69bb16;
$inputFieldBackground: #262f3b;
$primary-black: #171717;
$fade_yellow :#fff872