$light: 300;
$regular: 400;
$bold: 700;

$main-font-family: "Basic-Sans", sans-serif;
$cairo-font-family: "Cairo", sans-serif;

@font-face {
	font-family: "Basic-Sans";
	src: url("../../fonts/Basic-Regular.ttf");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "Basic-Sans";
	src: url("../../fonts/BasicSans-Bold.ttf");
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: "Basic-Sans";
	src: url("../../fonts/BasicSans-SemiBold.ttf");
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: "Basic-Sans";
	src: url("../../fonts/BasicSans-Black.ttf");
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: "Open-Sans";
	src: url("../../fonts/OpenSans_Condensed-Regular.ttf");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "Cairo";
	src: url("../../fonts/Cairo-Regular.ttf");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "Cairo";
	src: url("../../fonts/Cairo-Bold.ttf");
	font-weight: 600;
	font-style: normal;
}

h1,
h2,
h3 {
	line-height: 1.1;
	font-weight: 700;
	margin-top: 0;
}

p {
	margin-top: 0;
}

a,
a:visited,
a:active {
	text-decoration: none;
}
