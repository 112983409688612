.translate-y50 {
    transform: translateY(50%);
}

.translate-y-50 {
    transform: translateY(-50%);
}

.translate-x50 {
    transform: translateX(50%);
}

.translate-x-50 {
    transform: translateX(-50%);
}

.break-large-text{
    overflow: hidden;
    white-space: normal; /* Allows wrapping */
    text-overflow: ellipsis;
    // display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-word; /* Forces long words to break */
    overflow-wrap: break-word; /* Ensures wrapping for long words */
}

.diagonal-strike {
    position: relative;
    display: inline-block;
}
.diagonal-strike::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    border-top: 2px solid #D6303B;
    transform: rotate(-10deg);
}