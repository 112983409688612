@import "variables";
@import "typography";

html {
	box-sizing: border-box;
	font-size: 100%;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}
body {
	height: 100%;
	background-color: $dark-blue;
	margin: 0;
	padding: 0;
	min-height: 100% !important;
	overflow-x: hidden;
	font: inherit;
	font-size: 100%;
	color: #000;
}

input:focus {
	outline: none;
	box-shadow: none;
}

li {
	list-style: none;
}

a {
	cursor: pointer;
}
